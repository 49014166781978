body {
  color: var(--contrast-color-step-1);
}

h1 {
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 8px;
  margin: 32px 0;
  border-bottom: 2px solid var(--ion-color-secondary);
}

h2 {
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 32px;
}

bh-group-box {
  h3 {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

ion-button {
  height: 40px;
  text-transform: none;
  letter-spacing: normal;
  font-size: 17px;
}

ion-content {
  &.safe-area-margin-top {
    --padding-top: var(--ion-safe-area-top);
  }

  &.safe-area-margin-bottom {
    --padding-bottom: var(--ion-safe-area-bottom);
  }

  &.transparent {
    --background: transparent;
  }
}

.safe-area-margin-top {
  --padding-top: var(--ion-safe-area-top);
  padding-top: var(--ion-safe-area-top);
}

.safe-area-margin-bottom {
  --padding-bottom: var(--ion-safe-area-bottom);
  padding-bottom: var(--ion-safe-area-bottom);
}

.loader-backdrop {
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  transition: opacity 0.2s;
  opacity: 0;
  pointer-events: none;
  z-index: 999999;

  .spinner-wrapper {
    // margin-top: 80px;
    transition: margin-top 0.2s;

    .spinner-background {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 16px;
      background: #333;
      padding: 32px;
      transform: scale(120%);
      transition: opacity 0.2s, transform 0.3s;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);

      ion-spinner {
        --color: var(--ion-color-primary);
        //   color: var(--ion-color-primary);
        //   border-width: 2px;

        svg {
          stroke-width: 2px;
        }
      }

      .loading-message {
        margin-top: 24px;
        font-size: 14px;
        text-align: center;
        color: #fff;
      }
    }
  }

  &.show {
    opacity: 1;
    pointer-events: unset;

    .spinner-wrapper {
      //   margin-top: 0;

      .spinner-background {
        transform: scale(100%);
      }
    }
  }
}

ion-menu {
  .user-menu-header {
    padding: 16px;
    display: flex;
    align-items: center;
    color: var(--contrast-color-step-1);

    bh-user-icon {
      margin-right: 16px;
    }

    .sign-in {
      flex: 1 1 auto;
    }

    .user-name {
      flex: 1 1 auto;

      .user-role {
        display: block;
        font-size: 14px;
      }
    }

  }

  .user-menu-toggle {
    max-width: 50px;
  }

  .close-button {
    ion-icon {
      color: var(--contrast-color-step-1);
    }
  }
}

ion-modal {
  --border-radius: 32px;

  &.modal-form {
    --max-width: 1024px;
    --width: 100%;
  }
}
