/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
// @import "@ionic/angular/css/palettes/dark.class.css";
// @import '@ionic/angular/css/palettes/dark.system.css';

*,
ion-item,
ion-label {
  font-family: 'Figtree', sans-serif !important;
  font-smooth: always;
  --webkit-font-smoothing: subpixel-antialiased;
  --moz-osx-font-smoothing: grayscale;
}

ion-button.md.button-outline {
  --border-width: 1px;
}

ion-popover.field-popover {
  // --border-radius: 32px;
  --width: 350px;

  &::part(content) {
    border-radius: 16px;
  }
}
